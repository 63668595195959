import { graphql, useStaticQuery } from "gatsby"
import {
  ArticleCardType,
  articleCardFromMap,
  articlesCardsListFromMap,
} from "../types/article_card_type"

export type MultiQueriesHomeBlog = {
  mainArticle: ArticleCardType
  analysisArticles: ArticleCardType[]
  learnArticles: ArticleCardType[]
  mainArticleEN: ArticleCardType
  analysisArticlesEN: ArticleCardType[]
  learnArticlesEN: ArticleCardType[]
}

export const GetHomeBlogArticlesPT = () => {
  let mainArticle: ArticleCardType
  let analysisList: ArticleCardType[] = []
  let learnList: ArticleCardType[] = []
  let mainArticleEN: ArticleCardType
  let analysisListEN: ArticleCardType[] = []
  let learnListEN: ArticleCardType[] = []
  var queryResult: MultiQueriesHomeBlog

  const allArticles = useStaticQuery(
    graphql`
      query HomeArticles {
        # Articles in PT
        mainArticle: allStrapiArticle(
          limit: 1
          filter: { locale: { eq: "pt" } }
          sort: { strapi_id: DESC }
        ) {
          edges {
            node {
              locale
              ...PrincipalCardFields
            }
          }
        }
        analysisArticles: allStrapiArticle(
          limit: 2
          filter: { category: { eq: "analysis" }, locale: { eq: "pt" } }
          sort: {publishedAt: DESC}
        ) {
          edges {
            node {
              locale
              ...ArticleCardFields
            }
          }
        }
        learnArticles: allStrapiArticle(
          limit: 9
          filter: { category: { eq: "learn" }, locale: { eq: "pt" } }
          sort: {publishedAt: DESC}
        ) {
          edges {
            node {
              locale
              ...ArticleCardFields
            }
          }
        }

        # Articles in EN
        mainArticleEN: allStrapiArticle(
          limit: 1
          filter: { locale: { eq: "en" } }
          sort: { strapi_id: DESC }
        ) {
          edges {
            node {
              locale
              ...PrincipalCardFields
            }
          }
        }
        analysisArticlesEN: allStrapiArticle(
          limit: 2
          filter: { category: { eq: "analysis" }, locale: { eq: "en" } }
          sort: {publishedAt: DESC}
        ) {
          edges {
            node {
              locale
              ...ArticleCardFields
            }
          }
        }
        learnArticlesEN: allStrapiArticle(
          limit: 9
          filter: { category: { eq: "learn" }, locale: { eq: "en" } }
          sort: {publishedAt: DESC}
        ) {
          edges {
            node {
              locale
              ...ArticleCardFields
            }
          }
        }
      }
    `
  )

  mainArticle = articleCardFromMap(allArticles.mainArticle.edges)

  analysisList = articlesCardsListFromMap(allArticles.analysisArticles.edges)

  learnList = articlesCardsListFromMap(allArticles.learnArticles.edges)

  mainArticleEN = articleCardFromMap(allArticles.mainArticleEN.edges)

  analysisListEN = articlesCardsListFromMap(
    allArticles.analysisArticlesEN.edges
  )

  learnListEN = articlesCardsListFromMap(allArticles.learnArticlesEN.edges)

  queryResult = {
    mainArticle: mainArticle!,
    analysisArticles: analysisList,
    learnArticles: learnList,
    mainArticleEN: mainArticleEN!,
    analysisArticlesEN: analysisListEN,
    learnArticlesEN: learnListEN,
  }

  return queryResult
}
